@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bg-gradient-radial {
  background: radial-gradient(circle at center, var(--tw-gradient-from), var(--tw-gradient-to));
}

/* Smooth scrolling for the entire page */
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  background: var(--background);
}

body {
  overflow-x: hidden;
  background: var(--background);
  color: var(--text-primary);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Updated elegant color scheme */
:root {
  --primary: #e0e7ff;
  --secondary: #c7d2fe;
  --accent: #818cf8;
  --background: #030712;
  --surface: rgba(255, 255, 255, 0.03);
  --text-primary: #f1f5f9;
  --text-secondary: #94a3b8;
  --accent-primary: #e0e7ff;
  --accent-secondary: #c7d2fe;
  --accent-bright: #818cf8;
}

/* Enhanced scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--background);
}

::-webkit-scrollbar-thumb {
  background: var(--accent-bright);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--accent-secondary);
}

/* Refined glass effect */
.glass {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  box-shadow: 
    0 4px 30px rgba(0, 0, 0, 0.1),
    inset 0 0 20px rgba(255, 255, 255, 0.02);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.glass:hover {
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 
    0 8px 40px rgba(0, 0, 0, 0.2),
    inset 0 0 30px rgba(255, 255, 255, 0.03),
    0 0 20px rgba(224, 231, 255, 0.1);
  transform: translateY(-5px);
}

/* Enhanced hover effects */
.hover-lift {
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.hover-lift:hover {
  transform: translateY(-12px) scale(1.02);
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.2),
    0 0 20px rgba(99, 102, 241, 0.1);
}

/* Subtle text gradient */
.text-gradient {
  background: linear-gradient(to right, var(--accent-primary), var(--accent-bright));
  background-size: 200% auto;
  animation: textShine 8s ease infinite;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.9;
}

@keyframes textShine {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Glow effect */
.glow {
  position: relative;
}

.glow::after {
  content: '';
  position: absolute;
  inset: -1px;
  background: linear-gradient(
    45deg,
    rgba(224, 231, 255, 0.1),
    rgba(199, 210, 254, 0.1),
    rgba(129, 140, 248, 0.1)
  );
  filter: blur(15px);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
  border-radius: inherit;
}

.glow:hover::after {
  opacity: 0.3;
}

/* Enhanced animated background */
.animated-bg {
  background: linear-gradient(
    -45deg,
    var(--background),
    var(--primary),
    var(--secondary),
    var(--accent)
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  opacity: 0.8;
}

/* New shimmer effect */
.shimmer {
  position: relative;
  overflow: hidden;
}

.shimmer::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(30deg);
  animation: shimmer 6s linear infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%) rotate(30deg);
  }
  100% {
    transform: translateX(100%) rotate(30deg);
  }
}

/* Particle animation styles */
@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10px, 10px);
  }
  50% {
    transform: translate(-5px, 20px);
  }
  75% {
    transform: translate(-15px, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.animate-float {
  will-change: transform;
  transform: translateZ(0);
}

/* Section transitions */
.section-transition {
  transition: transform 0.8s cubic-bezier(0.65, 0, 0.35, 1),
              opacity 0.8s cubic-bezier(0.65, 0, 0.35, 1);
}

/* Section styling */
section {
  position: relative;
  z-index: 1;
  padding: 4rem 2rem;
  background-color: var(--background);
}

/* Footer styling */
footer {
  position: relative;
  z-index: 1;
  scroll-snap-align: start;
  width: 100%;
  padding: 2rem;
}

/* Progress indicator */
.scroll-progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #60a5fa, #34d399);
  transform-origin: 0%;
  z-index: 1000;
}

/* Hide scrollbar but maintain functionality */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

/* Typing cursor animation */
.typing-cursor {
  display: inline-block;
  width: 2px;
  animation: blink 0.7s infinite;
  margin-left: 4px;
  color: #94a3b8;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}